define("ember-svg-jar/inlined/animal-surgery-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.875 15a2 2 0 11-4 0 2 2 0 014 0zM14.875 17a1 1 0 100 2h.971l-.04.163a2 2 0 01-2.976 1.245l-.439-.264a1 1 0 10-1.032 1.712l.438.265c1.735 1.046 3.856.603 5.078-.79 1.221 1.393 3.343 1.836 5.077.79l.44-.265a1 1 0 10-1.033-1.712l-.44.264a2 2 0 01-2.976-1.245l-.04-.163h.972a1 1 0 000-2h-4z\"/><path d=\"M2.875 3.972c0-1.22 1.38-1.93 2.372-1.221L8.92 5.375c2.368-1.673 5.008-2.653 7.954-2.653 8.117 0 14 6.207 14 13.278 0 7.594-6.406 14-14 14s-14-6.406-14-14V3.972zm2 .971V16c0 6.49 5.51 12 12 12 6.23 0 11.557-5.078 11.974-11.225l-4.002-2.112a2 2 0 11-2.806-1.48l-11.85-6.255c-.218.15-.435.31-.65.475a1 1 0 01-1.192.022L4.875 4.943zm14.673.047l5.01 7.258 4.207 2.22c-.657-4.524-4.182-8.44-9.217-9.478zm-2.568-.267h-.105c-1.635 0-3.173.356-4.633 1.026l8.492 4.482-3.628-5.257a1 1 0 01-.126-.251z\"/>",
    "attrs": {
      "width": "33",
      "height": "32",
      "viewBox": "0 0 33 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});