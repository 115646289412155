define("ember-svg-jar/inlined/caravan-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.5 6h16a2 2 0 012 2v16h-2V12.5A1.5 1.5 0 0021 11h-4a1.5 1.5 0 00-1.5 1.5V24h-2.035a3.5 3.5 0 00-6.93 0H4.5V8a2 2 0 012-2zm20 18V8a4 4 0 00-4-4h-16a4 4 0 00-4 4v16a2 2 0 002 2h2.337a3.5 3.5 0 006.326 0H26.5v1a1 1 0 102 0v-1h.5a1.5 1.5 0 001.5-1.5V22a1 1 0 00-2 0v2h-2zm-6 0h-3V13h3v11zm-12-11v3h3v-3h-3zm-2-.5A1.5 1.5 0 018 11h4a1.5 1.5 0 011.5 1.5v4A1.5 1.5 0 0112 18H8a1.5 1.5 0 01-1.5-1.5v-4zM10 23a1.5 1.5 0 110 3 1.5 1.5 0 010-3z\"/>",
    "attrs": {
      "width": "33",
      "height": "32",
      "viewBox": "0 0 33 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});