define("ember-svg-jar/inlined/auto-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.75 17a1 1 0 100 2h3a1 1 0 100-2h-3zM21.75 17a1 1 0 100 2h3a1 1 0 100-2h-3z\"/><path d=\"M6.46 8.02A7 7 0 0112.792 4h7.914a7 7 0 016.334 4.02L29.385 13h.365a1 1 0 110 2v10a3 3 0 01-3 3h-1a3 3 0 01-3-3v-1.75a1 1 0 01.032-.25H10.719a1 1 0 01.031.25V25a3 3 0 01-3 3h-1a3 3 0 01-3-3V15a1 1 0 010-2h.366l2.343-4.98zM5.75 15v6h22v-6h-22zm19.481-6.129A5 5 0 0020.707 6h-7.914a5 5 0 00-4.524 2.871L6.326 13h20.848l-1.943-4.129zM24.718 23a1 1 0 01.032.25V25a1 1 0 001 1h1a1 1 0 001-1v-2h-3.032zM5.75 23v2a1 1 0 001 1h1a1 1 0 001-1v-1.75c0-.086.01-.17.031-.25H5.75z\"/>",
    "attrs": {
      "width": "33",
      "height": "32",
      "viewBox": "0 0 33 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});