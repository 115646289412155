define("ember-svg-jar/inlined/private-pension-provision", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.204 5.653a2.94 2.94 0 015.705-1.426L11.102 9h10.212a3.5 3.5 0 011.942.588l.87.58a1 1 0 01-1.11 1.664l-.87-.58a1.5 1.5 0 00-.832-.252h-.743v3h2a3 3 0 01.432 5.97l1.005 2.679a.989.989 0 01.03.098c.97-.267 1.875-.544 2.656-.798 1.863-.606 3.877.754 3.877 2.775a2.8 2.8 0 01-1.864 2.656c-2.36.827-7.931 2.62-12.136 2.62-4.204 0-9.775-1.793-12.136-2.62a2.8 2.8 0 01-1.864-2.656c0-2.021 2.015-3.381 3.878-2.775.78.254 1.686.531 2.655.798.008-.033.019-.066.031-.098l1.077-2.873a5 5 0 01-3.368-3.563l-2.64-10.56zM12.264 20l-1.215 3.24c1.88.436 3.844.76 5.522.76 1.679 0 3.643-.324 5.523-.76L20.878 20h-8.614zm9.33-2h.977a1 1 0 100-2h-9.438a3 3 0 01-2.91-2.272L7.968 4.712a.94.94 0 00-1.825.456l2.64 10.56A3 3 0 0011.694 18H21.594zm-3.023-4v-3h-2v3h2zm-4 0v-3h-2.969l.56 2.242a1 1 0 00.97.758h1.44zm-8.74 9.85c-.631-.204-1.26.274-1.26.874a.8.8 0 00.526.768C7.481 26.328 12.752 28 16.571 28c3.82 0 9.09-1.672 11.474-2.508a.8.8 0 00.526-.768c0-.6-.628-1.078-1.259-.873-2.72.884-7.205 2.149-10.74 2.149-3.537 0-8.022-1.265-10.742-2.15z\"/>",
    "attrs": {
      "width": "33",
      "height": "32",
      "viewBox": "0 0 33 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});