define("ember-svg-jar/inlined/supplementary-health-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.533 8.025L11.367 9.4a3 3 0 001.8.6H22.5a1 1 0 010 2H12.176a1 1 0 01-.607-.205L8.471 9.429a.88.88 0 011.062-1.404zM25.33 12a3 3 0 00-2.83-4h-9.332a1 1 0 01-.6-.2l-1.834-1.375a2.88 2.88 0 00-3.476 4.593L8.543 12H4.5V8a1 1 0 00-2 0v7a3 3 0 003 3h4.764l4 2-3.878 1.939a3.5 3.5 0 101.015 1.729l5.099-2.55 5.1 2.55a3.5 3.5 0 101.015-1.729L18.735 20l4-2H26.5a3 3 0 003-3V8a1 1 0 10-2 0v4h-2.17zm2.171 2v1a1 1 0 01-1 1h-3.98a.97.97 0 00-.042 0H10.522a.97.97 0 00-.042 0H5.5a1 1 0 01-1-1v-1h23zm-9.236 4l-1.764.882L14.736 18h3.528zM8 23a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm17 0a1.5 1.5 0 110 3 1.5 1.5 0 010-3z\"/>",
    "attrs": {
      "width": "33",
      "height": "32",
      "viewBox": "0 0 33 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});