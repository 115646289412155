define("ember-svg-jar/inlined/fallback-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.803 4c-.165 0-.395.089-.621.314-.812.808-2.423 2.034-5.014 2.499C7.444 6.943 7 7.487 7 8v9.338a9 9 0 004.37 7.717L16 27.834l4.63-2.779A9 9 0 0025 17.338V8c0-.516-.445-1.058-1.161-1.181-2.804-.481-4.64-1.805-5.507-2.578-.194-.173-.388-.241-.535-.241h-3.994zM11.77 2.897C12.255 2.414 12.964 2 13.803 2h3.994c.744 0 1.394.327 1.865.747.676.602 2.18 1.7 4.515 2.1C25.638 5.1 27 6.308 27 8v9.338a11 11 0 01-5.34 9.432l-4.631 2.779a2 2 0 01-2.058 0l-4.63-2.779A11 11 0 015 17.338V8c0-1.696 1.365-2.895 2.815-3.156 2.108-.378 3.366-1.36 3.956-1.947zm3.08 4.105a.11.11 0 00-.029.017c-.394.3-1.139.836-2.046 1.33-.794.433-1.77.866-2.776 1.054v7.775a6 6 0 002.755 5.047L16 24.311l3.244-2.086A6 6 0 0022 17.178V9.403c-1.006-.188-1.982-.62-2.776-1.054a16.579 16.579 0 01-2.046-1.33.11.11 0 00-.03-.017L17.138 7h-2.276l-.01.002zm-1.24-1.574A2.069 2.069 0 0114.861 5h2.276c.468 0 .904.163 1.252.428a14.59 14.59 0 001.791 1.165c.802.437 1.666.79 2.44.883C23.326 7.561 24 8.148 24 9v8.178a8 8 0 01-3.674 6.73l-3.785 2.433a1 1 0 01-1.082 0l-3.785-2.433A8 8 0 018 17.178V9c0-.852.675-1.439 1.38-1.524.773-.093 1.637-.446 2.438-.883a14.59 14.59 0 001.792-1.165zm6.142 6.913a1 1 0 01-.095 1.412l-4 3.5a1 1 0 01-1.258.047l-2-1.5a1 1 0 011.2-1.6l1.351 1.014 3.39-2.967a1 1 0 011.412.095z\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});