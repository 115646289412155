define("ember-svg-jar/inlined/company-pension", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.25 7V6a3 3 0 013-3h4a3 3 0 013 3v1h5a4 4 0 014 4v13a4 4 0 01-4 4h-20a4 4 0 01-4-4V11a4 4 0 014-4h5zm2-1a1 1 0 011-1h4a1 1 0 011 1v1h-6V6zm-7 3h20a2 2 0 012 2v3h-24v-3a2 2 0 012-2zm6 7h-8v8a2 2 0 002 2h20a2 2 0 002-2v-8h-8v1a2 2 0 01-2 2h-4a2 2 0 01-2-2v-1zm6 0v1h-4v-1h4z\"/>",
    "attrs": {
      "width": "33",
      "height": "32",
      "viewBox": "0 0 33 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});