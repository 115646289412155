define("ember-svg-jar/inlined/bike-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.625 8a1 1 0 011-1h2a1 1 0 01.949.684L14.679 11h4.892l-.895-2.684A1 1 0 0119.625 7h3a1 1 0 110 2h-1.613l1.566 4.695c.214.672.482 1.29.774 1.85a5 5 0 11-1.585 1.274 13.787 13.787 0 01-1.093-2.508L20.238 13h-4.892l2.228 6.684a1 1 0 01-1.898.632L13.238 13h-.059l-2.11 3.376a5 5 0 11-1.695-1.061l2.403-3.845a1 1 0 01.795-.469L11.904 9h-1.279a1 1 0 01-1-1zm-1.349 9.07a3 3 0 101.696 1.06l-1.499 2.4a1 1 0 01-1.696-1.06l1.5-2.4zm14.681 1.556a3 3 0 101.467-1.376c.146.196.29.379.43.548.394.475.75.836 1.004 1.075a8.031 8.031 0 00.368.327l.015.012a1 1 0 01-1.232 1.576l-.003-.002-.003-.003-.01-.008-.032-.025a10.036 10.036 0 01-.475-.42 14.116 14.116 0 01-1.529-1.704z\"/>",
    "attrs": {
      "width": "33",
      "height": "32",
      "viewBox": "0 0 33 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});