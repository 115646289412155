import { CONFIG } from '@clark-utils/business-de';
import config from '@clarksource/client/config/environment';
import type { BrowserOptions } from '@sentry/browser';
import * as Sentry from '@sentry/capacitor';
import * as SentryEmber from '@sentry/ember';

const disallowedEnvironments = new Set(['circle', 'development', 'test']);

export function initializeSentry() {
  const { APP, sentry, sentryTags, capacitor } = config;

  // Check if Sentry should not be allowed to run
  if (disallowedEnvironments.has(sentry.environment)) {
    return;
  }

  try {
    const getCapacitorSentryDsn = () => {
      // @ts-expect-error It doesn't know about the `Capacitor` global
      const currentPlatform = window.Capacitor?.getPlatform();
      if (currentPlatform === 'ios') {
        return capacitor.ios.sentry.dsn;
      }
      if (currentPlatform === 'android') {
        return capacitor.android.sentry.dsn;
      }
      return sentry.dsn;
    };

    Sentry.init<BrowserOptions>(
      {
        ...sentry,
        dsn: getCapacitorSentryDsn(),
        release: APP.version,
        integrations: [
          SentryEmber.extraErrorDataIntegration(),
          SentryEmber.sessionTimingIntegration(),
        ],

        beforeSend(event, hint) {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          const error = hint?.originalException;

          /**
           * Ignore aborted route transitions from the Ember.js router.
           *
           * @see https://simplabs.com/blog/2019/07/15/sentry-and-ember/#filtering-errors
           * @see https://docs.sentry.io/error-reporting/configuration/filtering/?platform=browsernpm#before-send
           * @see https://github.com/emberjs/ember.js/issues/12505
           */
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (error instanceof Error && error?.name === 'TransitionAborted') {
            return null;
          }

          // @ts-expect-error TS(2339) FIXME: Property 'sentryExtra' does not exist on type 'str... Remove this comment to see the full error message
          if (error && typeof error.sentryExtra! === 'object') {
            if (!event.extra) {
              event.extra = {};
            }

            // @ts-expect-error TS(2339) FIXME: Property 'sentryExtra' does not exist on type 'str... Remove this comment to see the full error message
            Object.assign(event.extra, error.sentryExtra!);
          }

          return event;
        },
      },
      // Overwrite the DSN to ensure it's the default one on the ember-app
      (options) => SentryEmber.init({ ...options, dsn: sentry.dsn }),
    );

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (sentryTags) {
      Sentry.getCurrentScope().setTags(sentryTags);
    }

    // @TODO we need to look into if we actually need this anymore
    // I will have a look when I hook up VKB sentry soon
    if (CONFIG.brand) {
      Sentry.getCurrentScope().setTags({
        brand: CONFIG.brand,
      });
    }
  } catch (error) {
    console.error(error);
  }
}

/**
 * Wraps an async function in a Sentry capture context and logs any error to
 * Sentry. The error is then re-thrown.
 */
export const wrapAsync = <T extends (...args: unknown[]) => Promise<unknown>>(
  fn: T,
  captureContext?: object,
): T =>
  (async (...args: Parameters<T>) => {
    try {
      return await fn(...args);
    } catch (error) {
      Sentry.captureException(error, captureContext);
      throw error;
    }
  }) as unknown as T;
