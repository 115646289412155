define("ember-svg-jar/inlined/personal-liability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.143 3a3 3 0 00-3 3v1.045c0 6.163 4.998 11.3 11.002 11.897l-.002.058v8h-5a1 1 0 100 2h12a1 1 0 100-2h-5v-8-.03c6.15-.435 11.333-5.649 11.333-11.925V6a3 3 0 00-3-3H15.143a1 1 0 00-1 1v1.692a1 1 0 00.196.594l.69.935-.754 2.246-.163-.638a1 1 0 00-.453-.61l-1.87-1.126-.666-3.291a1 1 0 00-.98-.802h-3zm-1 3a1 1 0 011-1h2.182l.59 2.92a1 1 0 00.465.659l1.895 1.142.899 3.526a1 1 0 001.917.071l2-5.954a1 1 0 00-.144-.913l-.804-1.088V5h9.333a1 1 0 011 1v1.045C26.476 12.45 21.763 17 16.31 17S6.143 12.449 6.143 7.045V6z\"/>",
    "attrs": {
      "width": "33",
      "height": "32",
      "viewBox": "0 0 33 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});