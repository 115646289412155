define("ember-svg-jar/inlined/pet-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.286 7.5a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0zm3.5-1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM11.029 16.854C11.604 14.825 13.393 13 16.285 13c2.893 0 4.682 1.825 5.258 3.854.164.58.453 1.02.813 1.252.67.431 1.38.983 1.93 1.648.552.664 1 1.51 1 2.503 0 1.39-.366 2.785-1.107 3.86C23.424 27.215 22.255 28 20.751 28c-1.059 0-2.275-.365-3.264-.82-.711-.328-1.692-.328-2.403 0-.989.455-2.205.82-3.264.82-1.499 0-2.661-.78-3.414-1.819-.74-1.022-1.12-2.33-1.12-3.573 0-2.17 1.62-3.641 2.924-4.495.365-.239.655-.682.819-1.26zM16.285 15c-1.898 0-2.973 1.132-3.332 2.4-.247.87-.753 1.8-1.647 2.386-1.185.776-2.02 1.726-2.02 2.822 0 .865.27 1.753.74 2.4.456.63 1.061.992 1.794.992.665 0 1.584-.249 2.427-.637 1.243-.572 2.834-.572 4.077 0 .843.388 1.762.637 2.427.637.728 0 1.326-.356 1.78-1.016.47-.681.755-1.659.755-2.727 0-.368-.165-.776-.539-1.227-.373-.45-.9-.873-1.473-1.242-.905-.583-1.41-1.52-1.655-2.388-.36-1.267-1.435-2.4-3.334-2.4zM20.786 4a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm-1.5 3.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM2.286 13.5a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0zm3.5-1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM26.786 10a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm-1.5 3.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z\"/>",
    "attrs": {
      "width": "33",
      "height": "32",
      "viewBox": "0 0 33 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});