define("ember-svg-jar/inlined/household", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21.286 8a1 1 0 100-2 1 1 0 000 2zM18.286 7a1 1 0 11-2 0 1 1 0 012 0zM16.286 14a5 5 0 100 10 5 5 0 000-10zm-3 5a3 3 0 116 0 3 3 0 01-6 0z\"/><path d=\"M7.286 3a4 4 0 00-4 4v18a4 4 0 004 4h1a1 1 0 001 1h1a1 1 0 001-1h10a1 1 0 001 1h1a1 1 0 001-1h1a4 4 0 004-4V7a4 4 0 00-4-4h-18zm-2 4a2 2 0 012-2h3v4H5.452c-.056 0-.112.005-.166.014V7zm21.833 2H12.286V5h13a2 2 0 012 2v2.014A1.006 1.006 0 0027.119 9zM5.286 10.986c.054.01.11.014.166.014H27.12c.057 0 .113-.005.167-.014V25a2 2 0 01-2 2h-18a2 2 0 01-2-2V10.986z\"/>",
    "attrs": {
      "width": "33",
      "height": "32",
      "viewBox": "0 0 33 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});