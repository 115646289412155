define("ember-svg-jar/inlined/money", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24 6h-2V4.5A1.5 1.5 0 0020.5 3h-19A1.5 1.5 0 000 4.5v13A1.5 1.5 0 001.5 19H3v1.5c0 .827 1.173 1.5 2 1.5h19c.827 0 1-.673 1-1.5v-13c0-.827-.173-1.5-1-1.5zM1.5 18a.5.5 0 01-.5-.5v-13a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v13a.5.5 0 01-.5.5h-19zM24 21H5c-.276 0-1-.224-1-.5V19h16.5a1.5 1.5 0 001.5-1.5V7h2c.276 0 0 .224 0 .5v13c0 .276.276.5 0 .5zM11.5 7a4 4 0 100 8 4 4 0 000-8zm0 7a3 3 0 110-6 3 3 0 010 6zM20 5.5a.5.5 0 01-.5.5h-2a.5.5 0 110-1h2a.5.5 0 01.5.5zm-15 11a.5.5 0 01-.5.5h-2a.5.5 0 110-1h2a.5.5 0 01.5.5z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "25",
      "height": "25",
      "viewBox": "0 0 25 25",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});