define("ember-svg-jar/inlined/travel-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13 5h6a.5.5 0 01.5.5V7h-7V5.5A.5.5 0 0113 5zm8.5 2V5.5A2.5 2.5 0 0019 3h-6a2.5 2.5 0 00-2.5 2.5V7h-5a3 3 0 00-3 3v15a3 3 0 003 3h21a3 3 0 003-3V10a3 3 0 00-3-3h-5zm-12 2h13a5 5 0 005 5v7a5 5 0 00-5 5h-13a5 5 0 00-5-5v-7a5 5 0 005-5zm15 0h2a1 1 0 011 1v2a3 3 0 01-3-3zm3 14v2a1 1 0 01-1 1h-2a3 3 0 013-3zm-20 3h-2a1 1 0 01-1-1v-2a3 3 0 013 3zm-3-14v-2a1 1 0 011-1h2a3 3 0 01-3 3zm7.5 3a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-3.5 1.5a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0zm12.663-2.095a1.5 1.5 0 012.074.542l1.35 2.363a1.5 1.5 0 01-.53 2.03l-3.758 2.255a1.5 1.5 0 01-2.075-.542l-1.35-2.363a1.5 1.5 0 01.531-2.03l3.758-2.255zm.59 1.978l-2.9 1.74.855 1.494 2.9-1.74-.854-1.494z\"/>",
    "attrs": {
      "width": "33",
      "height": "32",
      "viewBox": "0 0 33 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});