define("ember-svg-jar/inlined/scooter-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20.26 4.327A1 1 0 0121 4h2.572a2.5 2.5 0 012.477 2.164l1.829 13.464c.925.565 1.542 1.375 1.852 1.855.577.894.016 1.92-.834 2.166a3.5 3.5 0 11-6.807 1.637c-.564-.143-1.044-.607-1.082-1.286H14v.333C14 26.535 12.08 28 10 28s-4-1.465-4-3.667V24H3.5A1.5 1.5 0 012 22.5v-2.954a8.999 8.999 0 014.787-7.956A1.997 1.997 0 016 10a3 3 0 013-3h4.5a2.5 2.5 0 012.027 3.963A2.999 2.999 0 0117 13.546V16h2.039c.43 0 .812-.273.951-.68.512-1.488.699-3.07.55-4.637l-.535-5.588a1 1 0 01.255-.768zM8 24v.333C8 25.19 8.767 26 10 26s2-.81 2-1.667V24H8zm-4-2h17.366c.409-1.122 1.265-2.332 3.047-2.825.485-.135.939-.188 1.36-.178L24.069 6.433A.5.5 0 0023.572 6H22.1l.43 4.493a13.036 13.036 0 01-.648 5.478A3.006 3.006 0 0119.039 18H16a1 1 0 01-1-1v-3.454a1 1 0 00-1-1h-3a7 7 0 00-7 7V22zm20.07 2.952a1.5 1.5 0 002.894-.781l-2.895.781zm3.495-3.015c-.581-.63-1.447-1.16-2.619-.835-1.173.325-1.653 1.213-1.839 2.038l4.458-1.203zm.294-14.927a1 1 0 011.131.849l.333 2.333a1 1 0 01-1.98.283L27.01 8.14a1 1 0 01.849-1.131zM9 9a1 1 0 00-1 1h5.5a.5.5 0 000-1H9zm-2 7a1 1 0 011-1h2a1 1 0 110 2H8a1 1 0 01-1-1z\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});