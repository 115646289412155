define("ember-svg-jar/inlined/disability-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 4a3 3 0 100 6 3 3 0 000-6zm-5 3a5 5 0 1110 0 5 5 0 01-10 0zm8.023 7H11a7 7 0 00-7 7v6a3 3 0 003 3h8.924c.954 0 1.87-.379 2.544-1.053l.197-.197a3.368 3.368 0 00-2.014-5.73L19.659 16H21a5 5 0 015 5v8a1 1 0 102 0v-8a7 7 0 00-7-7h-1.977zm-3.22 11h.48a1.368 1.368 0 01.967 2.335l-.197.197c-.299.3-.705.468-1.129.468h-1.407l1.285-3zm-3.462 3H7a1 1 0 01-.953-.695L15.468 16h2.015l-5.142 12zM6 24.238V21a5 5 0 015-5h1.865L6 24.238z\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});