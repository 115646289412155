export default {
  "payback-input__wrapper": "_payback-input__wrapper_k26juu",
  "payback-hint": "_payback-hint_k26juu",
  "question-icon": "_question-icon_k26juu",
  "payback-floater": "_payback-floater_k26juu",
  "mobile-skip-button-wrapper": "_mobile-skip-button-wrapper_k26juu",
  "mobile-skip-button": "_mobile-skip-button_k26juu",
  "logo-container": "_logo-container_k26juu",
  "payback-logo": "_payback-logo_k26juu"
};
