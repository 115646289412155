define("ember-svg-jar/inlined/trailer-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.5 10h14a2 2 0 012 2v7h-5.126a4.002 4.002 0 00-7.748 0H4.5v-7a2 2 0 012-2zm18 9v-7a4 4 0 00-4-4h-14a4 4 0 00-4 4v7a2 2 0 002 2h1v1a1 1 0 102 0v-1h2.126a4.002 4.002 0 007.748 0H29a1.5 1.5 0 001.5-1.5V16a1 1 0 10-2 0v3h-4zm-11-1a2 2 0 110 4 2 2 0 010-4z\"/>",
    "attrs": {
      "width": "33",
      "height": "32",
      "viewBox": "0 0 33 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});