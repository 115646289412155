import type { Category, Company } from '@clark-utils/enums-and-types';
import ClarkRoute from '@clarksource/client/routes/-clark-route';
import type { CategoryItem } from '@clarksource/client/services/select-category';
import { action } from '@ember/object';
import type Transition from '@ember/routing/transition';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import { runTask } from 'ember-lifeline';

export default class MandateTagetingCompanyCategoryRoute extends ClarkRoute {
  @service declare category: Services['category'];
  @service declare router: Services['router'];
  @service declare selectCategory: Services['select-category'];
  @service declare api: Services['api'];
  @service declare tracking: Services['tracking'];

  queryParams = {
    placeholderId: {},
  };

  async beforeModel(transition: Transition) {
    super.beforeModel(transition);

    const { category } = this.selectCategory.getTemp() as CategoryItem;

    const { category: categoryId } = transition.to?.params as Record<
      string,
      string | undefined
    >;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (categoryId && !category) {
      const category = await this.api.get<Category>(`categories/${categoryId}`);
      this.selectCategory.addItem(category);
      this.selectCategory.setRedirect('index.manager');
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!category) {
      this.router.replaceWith('index.mandate.index.targeting');
    }
  }

  async model(params: { placeholderId: string }) {
    const { companies } = this.modelFor('index.mandate.index.targeting') as {
      companies: Company[];
    };

    const user = this.modelFor('index');

    this.selectCategory.setPlaceholderId(params.placeholderId);

    try {
      const itemBeingModified = this.selectCategory.getTemp() as CategoryItem;
      const categoryIdent = itemBeingModified.category.ident;

      const response = (await this.category.companies(categoryIdent)) as {
        companies: number[];
      };
      const companyIds = new Set(response.companies);

      const filteredCompanies =
        companyIds.size === 0
          ? companies
          : companies.filter((company) => companyIds.has(company.id));

      return {
        companies: filteredCompanies,
        user,
        onBack: this.onBack,
      };
    } catch {
      return {
        companies,
        user,
        onBack: this.onBack,
      };
    }
  }

  @action
  onBack() {
    this.tracking.track('manager__cat-select_click_company_item:back');
    runTask(this, () => window.history.back());
  }

  @action
  didTransition() {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    super.actions.didTransition.call(this, false);
  }
}
