import ClarkRoute from '@clarksource/client/routes/-clark-route';
import type { CategoryItem } from '@clarksource/client/services/select-category';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';

export default class MandateTargetingCompanyRoute extends ClarkRoute {
  @service declare router: Services['router'];
  @service declare selectCategory: Services['select-category'];

  beforeModel(...args: any) {
    // @ts-expect-error: A spread argument must either have a tuple type or be passed to a rest parameter
    super.beforeModel(...args);
    // This route can be accessed only when the user already selected a category in
    // the previous route, hence the check below
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!(this.selectCategory.getTemp() as CategoryItem).category) {
      this.router.replaceWith('index.mandate.index.targeting');
    }
  }

  async model() {
    return {
      // @ts-expect-error: Object is of type 'unknown'
      companies: this.modelFor('index.mandate.index.targeting').companies,
      user: this.modelFor('index'),
    };
  }

  @action
  didTransition() {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 2.
    super.actions.didTransition.call(this, false);
  }
}
