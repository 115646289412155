define("ember-svg-jar/inlined/dental-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.326 4.01c-1.224-.08-2.226.28-2.957 1.33-.795 1.14-1.244 2.6-1.244 4.054 0 2.14.954 3.77 2.798 4.65.545.26.843.841.767 1.422a32.884 32.884 0 00-.232 5.21c.083 2.274.438 4.797 1.365 6.886.154-.51.307-1.053.465-1.619l.195-.69c.284-1 .592-2.041.949-2.98.352-.928.779-1.83 1.327-2.515.556-.693 1.333-1.276 2.366-1.276 1.033 0 1.81.583 2.366 1.276.548.685.974 1.587 1.327 2.514.357.94.665 1.98.95 2.98l.194.692c.158.565.31 1.108.465 1.618.927-2.089 1.282-4.612 1.365-6.885.09-2.45-.14-4.506-.232-5.211a1.376 1.376 0 01.767-1.422c1.844-.88 2.798-2.51 2.798-4.65 0-1.455-.45-2.913-1.244-4.054-.731-1.05-1.733-1.41-2.957-1.33-1.28.085-2.75.662-4.165 1.474-1.01.58-2.258.58-3.268 0-1.415-.812-2.885-1.389-4.165-1.473zm.131-1.995c1.718.114 3.497.855 5.03 1.735.394.226.882.226 1.276 0 1.533-.88 3.312-1.621 5.03-1.735 1.775-.117 3.52.445 4.73 2.182 1.038 1.49 1.602 3.352 1.602 5.197 0 2.679-1.189 4.954-3.529 6.246.113.991.276 2.897.195 5.11-.095 2.581-.524 5.686-1.835 8.25-.347.678-1.027 1.072-1.758.99-.717-.081-1.288-.595-1.513-1.295-.223-.694-.44-1.466-.656-2.235l-.186-.661c-.283-.997-.571-1.965-.895-2.816-.328-.863-.665-1.534-1.018-1.974-.346-.432-.608-.527-.805-.527-.197 0-.459.095-.805.527-.353.44-.69 1.11-1.018 1.974-.324.851-.612 1.82-.895 2.816l-.186.66c-.216.77-.433 1.542-.656 2.236-.225.7-.796 1.214-1.512 1.294-.732.083-1.412-.311-1.759-.99-1.312-2.563-1.74-5.668-1.835-8.25-.08-2.212.082-4.118.195-5.11-2.34-1.29-3.529-3.566-3.529-6.245 0-1.845.564-3.707 1.602-5.197 1.21-1.737 2.955-2.3 4.73-2.182z\"/>",
    "attrs": {
      "width": "33",
      "height": "32",
      "viewBox": "0 0 33 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});