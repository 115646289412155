define("ember-svg-jar/inlined/public-health-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.714 2a1 1 0 011 1v1h1.694c.722 0 1.306.585 1.306 1.306V14a7.002 7.002 0 01-6 6.93v1.57a4.5 4.5 0 109 0V17c0-.06.006-.117.015-.174a3.5 3.5 0 111.985 2.837V22.5a6.5 6.5 0 01-13 0v-1.57a7.001 7.001 0 01-6-6.93V5.306C4.714 4.585 5.3 4 6.02 4h1.694V3a1 1 0 112 0v3a1 1 0 11-2 0h-1v8a5 5 0 0010 0V6h-1a1 1 0 01-2 0V3a1 1 0 011-1zm9 14.5a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0z\"/>",
    "attrs": {
      "width": "33",
      "height": "32",
      "viewBox": "0 0 33 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});