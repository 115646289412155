define("ember-svg-jar/inlined/life-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.714 2a3 3 0 100 6 3 3 0 000-6zm-1 3a1 1 0 112 0 1 1 0 01-2 0zM13.714 12.5a1.5 1.5 0 011.5-1.5h3a1.5 1.5 0 011.5 1.5V14a1 1 0 102 0v-1.5a3.5 3.5 0 00-3.5-3.5h-3a3.5 3.5 0 00-3.5 3.5V14a1 1 0 102 0v-1.5zM8.714 16.144v-2.908a3 3 0 00-6 0v5.292a3 3 0 00.317 1.341l3.618 7.237c.043.085.065.18.065.276A2.618 2.618 0 009.332 30h3.382a3 3 0 003-3v-4.394a3 3 0 00-.504-1.665l-2.554-3.832a3 3 0 00-3.942-.965zm-2.552-3.802a1 1 0 01.552.894V18a1 1 0 00.106.447l2 4a1 1 0 001.789-.894l-1.605-3.21.601-.401a1 1 0 011.387.277l2.554 3.832a1 1 0 01.168.555V27a1 1 0 01-1 1H9.332a.618.618 0 01-.618-.618c0-.407-.094-.807-.276-1.17L4.82 18.974a1 1 0 01-.106-.447v-5.292a1 1 0 011.448-.894zM24.714 13.236v2.908a3 3 0 00-3.941.965l-2.555 3.832a3 3 0 00-.504 1.665V27a3 3 0 003 3h3.382a2.618 2.618 0 002.618-2.618c0-.096.023-.19.066-.276l3.618-7.237a3 3 0 00.316-1.341v-5.292a3 3 0 00-6 0zm3.448-.894a1 1 0 01.552.894v5.292a1 1 0 01-.105.447l-3.618 7.236a2.618 2.618 0 00-.277 1.171.618.618 0 01-.618.618h-3.382a1 1 0 01-1-1v-4.394a1 1 0 01.168-.555l2.555-3.832a1 1 0 011.387-.277l.601.4-1.605 3.21a1 1 0 101.789.895l2-4a1 1 0 00.105-.447v-4.764a1 1 0 011.448-.894z\"/>",
    "attrs": {
      "width": "33",
      "height": "32",
      "viewBox": "0 0 33 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});