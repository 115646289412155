define("ember-svg-jar/inlined/private-health-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20.429 4a2 2 0 110 4 2 2 0 010-4zm-3.465 4h-3.198a3.504 3.504 0 012.663-1.965c.006.716.2 1.386.535 1.965zm-3.5 2h6.93a3.5 3.5 0 01-6.93 0zm8.965-.535A4 4 0 1016.964 4h-.035a5.5 5.5 0 105.5 5.5v-.035zm-12 8.535a4 4 0 00-4 4v7a1 1 0 11-2 0v-7a6 6 0 016-6h12a6 6 0 016 6v7a1 1 0 11-2 0v-7a4 4 0 00-4-4h-.05c.032.162.05.329.05.5v1.997c.124.054.245.119.36.194l.5.325a2.5 2.5 0 011.14 2.097V26a1 1 0 01-1 1h-.616a1 1 0 01-.384-1.923v-1.964a.5.5 0 00-.228-.42l-.5-.324a.5.5 0 00-.544 0l-.5.324a.5.5 0 00-.228.42v1.964A1 1 0 0120.044 27h-.615a1 1 0 01-1-1v-2.887a2.5 2.5 0 011.139-2.097l.5-.325c.116-.075.236-.14.36-.194V18.5a.5.5 0 00-.5-.5h-7a.5.5 0 00-.5.5v1.67a3.001 3.001 0 11-2 0V18.5c0-.171.018-.338.05-.5h-.05zm1 4a1 1 0 100 2 1 1 0 000-2z\"/>",
    "attrs": {
      "width": "33",
      "height": "32",
      "viewBox": "0 0 33 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});