define("ember-svg-jar/inlined/user-card-questionnaire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M46.08 8.672v54.922H2.88v5.781h48.96V8.672h-5.76z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.88 5.781h48.96c1.59 0 2.88 1.295 2.88 2.891v60.704c0 1.596-1.29 2.89-2.88 2.89H2.88A2.885 2.885 0 010 69.376V8.672c0-1.596 1.29-2.89 2.88-2.89zm0 63.594h48.96V8.672H2.88v60.703z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M34.56 24.57c0 3.992-3.224 7.227-7.2 7.227s-7.2-3.235-7.2-7.227c0-3.99 3.224-7.226 7.2-7.226 3.977 0 7.2 3.235 7.2 7.226zm-2.88 0a4.328 4.328 0 00-4.32-4.335 4.333 4.333 0 00-4.32 4.335 4.328 4.328 0 004.32 4.336 4.328 4.328 0 004.32-4.336zM40.32 47.696c-.02.484-.069.966-.144 1.445H14.544a12.895 12.895 0 01-.144-1.445c0-7.185 5.802-13.008 12.96-13.008 7.158 0 12.96 5.823 12.96 13.008zm-2.983-1.446c-.722-4.974-4.969-8.665-9.977-8.672-5.008.007-9.255 3.698-9.977 8.672h19.954z\" fill=\"#0439D7\"/><path d=\"M15.84 57.813h23.04c.795 0 1.44-.647 1.44-1.446 0-.798-.645-1.445-1.44-1.445H15.84c-.795 0-1.44.647-1.44 1.445 0 .799.645 1.446 1.44 1.446zM18.72 2.89H36c.795 0 1.44-.646 1.44-1.445C37.44.647 36.795 0 36 0H18.72c-.795 0-1.44.647-1.44 1.445 0 .799.645 1.446 1.44 1.446z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "55",
      "height": "73",
      "viewBox": "0 0 55 73",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});