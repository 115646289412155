define("ember-svg-jar/inlined/care-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.375 7a1 1 0 011 1v8h6a4 4 0 014-4h1v-.5a1.5 1.5 0 011.5-1.5h8.5a5 5 0 015 5v10a1 1 0 11-2 0v-4h-24v4a1 1 0 11-2 0V8a1 1 0 011-1zm1 11v1h11v-1h-11zm13 1h11v-4a3 3 0 00-3-3h-8v7zm-2-5h-1a2 2 0 00-2 2h3v-2zm-7-4a1 1 0 100 2 1 1 0 000-2zm-3 1a3 3 0 116 0 3 3 0 01-6 0z\"/>",
    "attrs": {
      "width": "33",
      "height": "32",
      "viewBox": "0 0 33 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});