/* eslint-disable ember/classic-decorator-no-classic-methods */
import type {
  AllPlugins,
  OpenEvent,
  PlatformService,
  RoutingService,
} from '@clark-shell/ember';
import { plugin, Plugins } from '@clark-shell/ember';
import { inject } from '@clark-shell/ember/di';
import type { ShellInfo } from '@clark-utils/tracking.js';
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import { addListener, removeListener } from '@ember/object/events';
import type Owner from '@ember/owner';
import type { Registry as Services } from '@ember/service';
import Service, { service } from '@ember/service';

/**
 * @TODO Move into `shell` composite / `native-app`, once we have a package
 * registry and can properly access the tracking events.
 */
export default class ShellTrackingService extends Service {
  @service declare tracking: Services['tracking'];
  @inject('service:shell/routing', { optional: true })
  declare routing?: RoutingService;
  @service('shell/platform') declare platform: PlatformService;

  @plugin({ optional: true }) declare auth?: AllPlugins['Auth'];
  @plugin({ optional: true }) declare device?: AllPlugins['Device'];
  @plugin({ optional: true }) declare app?: AllPlugins['App'];

  private readonly Plugins = Plugins as typeof Plugins & { isCapacitor: true };

  private shellInfo: Promise<ShellInfo | undefined> = (async () => {
    if (!this.auth || !this.device || !this.app) {
      return undefined;
    }

    const [{ installationId }, deviceInfo] = await Promise.all([
      this.auth.getInstallationId(),
      this.device.getInfo(),
    ]);
    const { operatingSystem, osVersion, manufacturer, model } = deviceInfo;

    const appVersion =
      deviceInfo.appVersion ?? (await this.app.getInfo()).version;
    const appBuild = deviceInfo.appBuild ?? (await this.app.getInfo()).build;

    return {
      appBuild,
      appVersion,
      installationId,
      manufacturer,
      model,
      operatingSystem: operatingSystem as 'ios' | 'android',
      osVersion,
      platform: this.platform.currentPlatform,
      userAgent: window.navigator.userAgent,
    };
  })();

  constructor(owner?: Owner) {
    super(owner);

    if (this.platform.isNative && this.routing) {
      addListener(this.routing as object, 'open', this.onOpen);

      registerDestructor(this, () => {
        removeListener(this.routing as object, 'open', this.onOpen);
      });
    }
  }

  @action
  private async onOpen(event: OpenEvent) {
    this.tracking.track('shell/app:opened', {
      resumed: true,
      type: event.type,
      source_url: event.url,
      destination: event.normalized,
      shell: await this.shellInfo,
      pathName: event.url,
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'shell/tracking': ShellTrackingService;
  }
}
