define("ember-svg-jar/inlined/accident-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20.5 5a3 3 0 116 0 3 3 0 01-6 0zm3-1a1 1 0 100 2 1 1 0 000-2z\"/><path d=\"M11.378 4.967a2.93 2.93 0 014.125.375l.666.798a1 1 0 00.768.36h1.62c.869 0 1.702.345 2.317.96l.08.08c.615.615 1.448.96 2.318.96 1.116 0 2.148.593 2.71 1.557l1.651 2.83a7 7 0 01.745 5.225l-.38 1.52a2.853 2.853 0 01-5.61-.444l-.126.351a5 5 0 01-4.048 3.275l-4.184.558a1 1 0 00-.7.436l-2.463 3.695a2.998 2.998 0 11-4.97-3.355l3.211-4.697c.09-.131.187-.257.291-.376l-.828.622a2.935 2.935 0 11-3.488-4.721l2.34-1.702a6 6 0 013.99-1.13l3.931.303.182-.455a5 5 0 01-3.474-1.7L11.05 9.146a2.93 2.93 0 01.329-4.18zm2.589 1.655a.93.93 0 10-1.413 1.207l1.003 1.147A3 3 0 0015.815 10H17a1 1 0 01.928 1.371l-1 2.5a1 1 0 01-1.005.626l-4.664-.359a4 4 0 00-2.66.754l-2.34 1.701a.935.935 0 101.112 1.504l1.905-1.43a3 3 0 012.099-.585l4.224.423a1 1 0 01.22 1.943l-4.049 1.366a2 2 0 00-1.01.766l-3.212 4.696a.998.998 0 101.654 1.117l2.464-3.694a3 3 0 012.1-1.31l4.184-.558a3 3 0 002.429-1.965l1.68-4.702a1 1 0 011.835-.111l.479.956a4 4 0 01.268 2.888l-.231.808a.853.853 0 101.648.442l.38-1.52a5 5 0 00-.532-3.732l-1.65-2.83a1.139 1.139 0 00-.984-.565c-1.4 0-2.742-.556-3.732-1.546l-.08-.08a1.276 1.276 0 00-.903-.374h-1.62a3 3 0 01-2.305-1.08l-.665-.798zM17 28a1 1 0 000 2h9a1 1 0 100-2h-9z\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});