import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! template-lint-disable no-at-ember-render-modifiers }}\n<div\n  data-test-messenger\n  local-class=\"messenger\"\n  {{style this.style}}\n  {{did-insert this.setupMessengerWindow}}\n>\n  <Conversation\n    @interact={{this.interact}}\n    @onClickAppointmentCta={{this.handleClickAppointmentCta}}\n    @open={{this.messengerWindow.open}}\n    @showUpgradeButton={{not this.isMandateCustomer}}\n    @upgradeLink={{link\n      \"customer.upgrade\"\n      query=(hash\n        callback=\"index.manager.landing-with-messenger\"\n      )\n    }}\n  />\n</div>", {"contents":"{{! template-lint-disable no-at-ember-render-modifiers }}\n<div\n  data-test-messenger\n  local-class=\"messenger\"\n  {{style this.style}}\n  {{did-insert this.setupMessengerWindow}}\n>\n  <Conversation\n    @interact={{this.interact}}\n    @onClickAppointmentCta={{this.handleClickAppointmentCta}}\n    @open={{this.messengerWindow.open}}\n    @showUpgradeButton={{not this.isMandateCustomer}}\n    @upgradeLink={{link\n      \"customer.upgrade\"\n      query=(hash\n        callback=\"index.manager.landing-with-messenger\"\n      )\n    }}\n  />\n</div>","moduleName":"@clarksource/client/components/messenger.hbs","parseOptions":{"srcName":"@clarksource/client/components/messenger.hbs"}});
import { CustomerState } from '@clark-customer/entities';
import type { AppStoreService, PlatformService } from '@clark-shell/ember';
import { RatingModalTrigger } from '@clark-utils/enums-and-types';
import { action } from '@ember/object';
import type { Registry as Services } from '@ember/service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface MessengerSignature {
  Args: {
    navigate?: () => void;
  };
}

type InteractionEvent = {
  type: 'navigate' | 'rate';
};

type Style = {
  position: string;
  top: string;
};

export default class MessengerComponent extends Component<MessengerSignature> {
  @service declare customer: Services['customer'];
  @service declare experiments: Services['experiments'];
  @service declare router: Services['router'];
  @service declare session: Services['session'];
  @service declare tracking: Services['tracking'];

  // eslint-disable-next-line ember/no-unused-services
  @service('messenger/window')
  declare messengerWindow: Services['messenger/window'];

  @service('rating/rating') declare rating: Services['rating/rating'];

  @service('shell/app-store') declare appStore: AppStoreService;
  @service('shell/platform') declare platform: PlatformService;

  @tracked defaultElementTop = 0;

  get isMandateCustomer(): boolean {
    const { customerState } = this.customer;

    const isClark2User =
      this.experiments.getVariant('business-strategy') === 'clark2';

    if (isClark2User) {
      return customerState === CustomerState.Mandate;
    }

    return (
      // just to be safe
      !customerState ||
      customerState === CustomerState.Legacy ||
      customerState === CustomerState.Mandate
    );
  }

  get style(): Style {
    const computedElementTop = this.defaultElementTop;

    return {
      position: 'absolute',
      top: `${computedElementTop}px`,
    };
  }

  private rateApp(): void {
    if (this.platform.isWeb) {
      // Let the <Rating> component show a modal instead
      this.rating.triggerEvent(
        RatingModalTrigger.RECEIVED_RATING_LINK_IN_MESSENGER,
      );

      this.rating.show();
    } else if (this.rating.ratingSettings.NATIVE_MODAL_ENABLED) {
      this.appStore.requestReviewOrOpenAppInStore();
    } else {
      this.appStore.openAppInStore();
    }
  }

  @action handleClickAppointmentCta(): void {
    const mandateId = this.session.currentMandate?.id;

    this.args.navigate?.();

    this.tracking.track('communication/messenger/direct-appointment:click', {
      mandateId,
    });

    this.router.transitionTo('customer.appointment-options');
  }

  @action interact(event: InteractionEvent): void {
    if (event.type === 'navigate') {
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.args.navigate();

      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (event.type === 'rate') {
      this.rateApp();
    }
  }

  @action setupMessengerWindow(element: HTMLElement): void {
    this.defaultElementTop = element.getBoundingClientRect().top;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Messenger: typeof MessengerComponent;
    messenger: typeof MessengerComponent;
  }
}
