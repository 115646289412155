import EmberRouter from '@ember/routing/router';

import config from './config/environment';

class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.routerRootURL;
}

Router.map(function () {
  this.route('access'); // need this for ios app login
  this.route('blank'); // a route we transition to when we are about to leave ember
  this.route('bounce-screen'); // TODO: remove in JCLARK-61512
  this.route('clark-tour');
  this.route('clark2');
  this.route('consent-primer');
  this.route('errors');
  this.route('fde-onboarding', { path: '/customer/fde-onboarding' });
  this.route('signup-with-promocode');
  this.route('signup');
  this.route('index', { path: '/' }, function () {
    this.route('about-us');
    this.route('article', { path: '/articles/:id' });
    this.route('category', { path: '/categories/:id' });
    this.route('data-protection');
    this.route('demandcheck', function () {
      this.route('index', { path: '/' }, function () {
        this.route('intro');
        this.route('primer');
        this.route('questionnaire', { path: '/' });
      });
    });
    this.route('feed');
    this.route('iban');
    this.route('imprint');
    this.route('invitations');
    this.route('invitees');
    this.route('mam');
    this.route('manager', function () {
      this.route('category', { path: '/categories/:id' });
      this.route('inquiry', { path: '/inquiries' }, function () {
        this.route('category', { path: '/:id/category/:category_id' });
        this.route('index', { path: '/:id' });
      });
      this.route('landing', { path: '/' }, function () {
        this.route('contracts', { path: '/' });
      });
      this.route('landing-with-messenger');
      this.route('pre-demandcheck');
      this.route('product', { path: '/products/:id' });
    });
    this.route('contracts-tab', function () {
      this.route('index', { path: '/' });
    });
    this.route('mandate', function () {
      this.route('index', { path: '/' }, function () {
        this.route('cockpit-preview');
        this.route('confirming');
        this.route('custom-funnel', { path: 'partner/:partner' });
        this.route('exit-poll');
        this.route('finished');
        this.route('iban');
        this.route('mam');
        this.route('new-intention-questionnaire');
        this.route('payback');
        this.route('phone-verification');
        this.route('profiling');
        this.route('register');
        this.route('sign-up');
        this.route('status');
        this.route('voucher-journey');
        this.route('update-account');
        this.route('targeting', function () {
          this.route('company');
          this.route('company-category', { path: '/company/:category' });
          this.route('index', { path: '/' });
          this.route('selection');
        });
      });
    });
    this.route('messenger');
    this.route('obligation');
    this.route('offer', { path: '/offer/:offer_id' });
    this.route('onboarding-information');
    this.route('questionnaire', function () {
      this.route('index', { path: '/:id' });
    });
    this.route('questionnaire-category', {
      path: '/questionnaire-category/:id',
    });
    this.route('refer-a-friend');
    this.route('retirement-landing');
    this.route('select-category');
    this.route('settings');
    this.route('terms');

    this.mount('@clarksource/engine-cockpit-retirement', {
      path: 'retirement',
      as: 'retirement',
    });
  });
  this.route('legal', { path: '/legal/:document' });
  this.route('login');
  this.route('redeem-voucher');
  this.route('route-password-reset', { path: '/password-reset' });
  this.route('sign-up', { path: '/confirmation' }, function () {
    this.route('thank-you');
  });
  this.route('support');
  this.route('update-native-app');

  this.mount('@clark-contracts/main', {
    path: 'contracts',
    as: 'contracts',
  });

  this.mount('@clark-customer/main', {
    path: 'customer',
    as: 'customer',
  });

  this.mount('@clark-offers/main', {
    path: 'offers',
    as: 'offers',
  });

  this.mount('@clark-partnerships/freyr', {
    path: 'freyr',
    as: 'freyr',
  });

  this.mount('@clark-recommendations/main', {
    path: 'recommendations',
    as: 'recommendations',
  });

  this.mount('@clark-retirement/main', {
    path: 'retirement-temporary',
    as: 'retirement-temporary',
  });

  this.mount('@clark-sales/main', {
    path: 'sales',
    as: 'sales',
  });
});

export default Router;
