export type Reason = {
  id: string;
  subreasonIds?: string[];
};

export function getReasons(): Reason[] {
  return [
    {
      id: 'social_media',
      subreasonIds: [
        'instagram_influencer',
        'facebook_advertisement',
        'instagram_advertisement',
      ],
    },
    {
      id: 'youtube',
      subreasonIds: ['youtube_influencer', 'youtube_advertisement'],
    },
    {
      id: 'referrals',
    },
    {
      id: 'google_search',
    },
    {
      id: 'podcast',
    },
    {
      id: 'online_advertisement',
    },
    {
      id: 'partner_website',
    },
  ];
}
