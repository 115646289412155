define("ember-svg-jar/inlined/residential-building-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20.414 4.474L17.77 2.442a1.5 1.5 0 00-1.829 0L3.247 12.207a1 1 0 101.22 1.586l.39-.3V27a1 1 0 100 2h24a1 1 0 100-2V13.492l.39.3a1 1 0 001.22-1.585L26.817 9.4a2 2 0 00-1.96-2.4h-.394l.522-2.608a2 2 0 00-3.68-1.415l-.891 1.497zm-3.276 5.503A2 2 0 0018.857 13h.5l-.42 1.44a2 2 0 003.584 1.67l3.301-4.952 1.036.797-.001.045v15h-6v-6.5a1.5 1.5 0 00-1.5-1.5h-5a1.5 1.5 0 00-1.5 1.5V27h-6V12l-.001-.045 10.001-7.693 2.527 1.943-2.246 3.772zM23.024 4l-1 5h2.833l-4 6 1.167-4h-3.167l4.167-7zm-4.167 23h-4v-6h4v6z\"/>",
    "attrs": {
      "width": "33",
      "height": "32",
      "viewBox": "0 0 33 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});