/* eslint sort-keys: "error" */
export const engines = {
  '@clark-contracts/main': {
    dependencies: {
      externalRoutes: {
        'buying-journey': 'offers.request',
        'contracts-list': 'index.manager.landing.contracts',
        'demandcheck-primer': 'index.demandcheck.index.primer',
        home: 'index.manager',
        'inquiry-details': 'index.manager.inquiry.index',
        login: 'login',
        'offers-checkout': 'offers.offer.checkout',
        'onboarding-intention': 'customer.onboarding.intention',
        'open-demandcheck-intro': 'recommendations.open-demandcheck-intro',
        'product-details': 'index.manager.product',
        questionnaire: 'index.questionnaire',
        registration: 'customer.registration',
        'targeting-step': 'index.mandate.index.targeting',
        upgrade: 'customer.upgrade',
        voucher: 'customer.voucher',
      },
      services: [
        {
          'external-router': 'router',
        },
        'api',
        'auth-redirect',
        'clark-user-agent',
        'config',
        'contracts/instant-advice',
        'customer',
        'experiments',
        'features',
        'intl',
        'local-storage',
        'mandate/fde-onboarding',
        'media',
        'messenger',
        'messenger/window',
        'nav-bar',
        'notification-banners',
        'notification-bar',
        'query',
        'shell/document-viewer',
        'shell/platform',
        'session',
        'session-storage',
        'title-bar',
        'tracking',
        'user',
        'zendesk-messenger',
      ],
    },
  },

  '@clark-customer/main': {
    dependencies: {
      externalRoutes: {
        'add-contract': 'contracts.create',
        'add-contract-for-category':
          'index.mandate.index.targeting.company-category',
        'add-contract-legacy': 'index.mandate.index.targeting',
        'app-messenger': 'index.messenger',
        'appointment-appreciation': 'sales.appointment.appreciation',
        'bu-education-slider': 'recommendations.education-slider',
        'buying-journey': 'offers.request',
        contracts: 'contracts',
        'contracts-list': 'contracts',
        'customer-onboarding': 'customer.onboarding',
        'customer-upgrade': 'customer.upgrade',
        'demandcheck-primer': 'index.demandcheck.index.primer',
        errors: 'errors',
        home: 'index',
        legal: 'legal',
        login: 'login',
        'manager-with-messenger': 'index.manager.landing-with-messenger',
        'mandate-funnel': 'index.mandate.index.status',
        'offers-awesome': 'offers.request.awesome',
        'open-demandcheck-intro': 'recommendations.open-demandcheck-intro',
        questionnaire: 'index.questionnaire',
        recommendations: 'recommendations',
        registration: 'customer.registration',
        'retirement-explorer': 'retirement-temporary',
        signup: 'signup',
        'targeting-category': 'contracts.create.company',
        'update-account': 'index.mandate.index.update-account',
        'voucher-journey': 'index.mandate.index.voucher-journey',
      },
      services: [
        {
          'external-router': 'router',
        },
        'api',
        'appointment-rules',
        'auth-redirect',
        'category',
        'clark-user-agent',
        'clark-version',
        'config',
        'customer',
        'experiments',
        'features',
        'firestarter',
        'intl',
        'legal',
        'local-storage',
        'mandate/fde-onboarding',
        'media',
        'messenger/window',
        'nav-bar',
        'notification-banners',
        'notification-bar',
        'questionnaire',
        'redirect-on-completion',
        'session',
        'session-storage',
        'settings',
        'shell/document-viewer',
        'shell/platform',
        'title-bar',
        'tracking',
        'zendesk-messenger',
      ],
    },
  },

  '@clark-offers/main': {
    dependencies: {
      externalRoutes: {
        'contracts-overview': 'contracts.index',
        'details.coverage-comparison': 'contracts.details.coverage-comparison',
        home: 'index.manager',
        'mandate-funnel': 'index.mandate.index.status',
        'offer-view': 'offers.offer.details',
        'open-demandcheck-intro': 'recommendations.open-demandcheck-intro',
        questionnaire: 'index.questionnaire.index',
        registration: 'customer.registration',
        'sales.checkout': 'sales.checkout',
        upgrade: 'customer.upgrade',
        voucher: 'customer.voucher',
      },
      services: [
        {
          'external-router': 'router',
        },
        {
          rating: 'rating/rating',
        },
        'api',
        'category',
        'clark-version',
        'config',
        'customer',
        'experiments',
        'features',
        'intl',
        'local-storage',
        'mandate/fde-onboarding',
        'media',
        'messenger/window',
        'nav-bar',
        'notification-banners',
        'offer',
        'questionnaire',
        'session',
        'session-storage',
        'title-bar',
        'tracking',
        'zendesk-messenger',
      ],
    },
  },

  '@clark-partnerships/freyr': {
    dependencies: {
      externalRoutes: {
        home: 'index',
        login: 'login',
      },
      services: [
        {
          'external-router': 'router',
        },
        'api',
        'config',
        'experiments',
        'features',
        'intl',
        'nav-bar',
        'notification-banners',
        'session',
      ],
    },
  },

  '@clark-recommendations/main': {
    dependencies: {
      externalRoutes: {
        'add-contract': 'contracts.create',
        'add-contract-for-category':
          'index.mandate.index.targeting.company-category',
        'appointment-options': 'customer.appointment-options',
        category: 'index.manager.category',
        'customer-appointment': 'customer.appointment',
        demandcheck: 'recommendations.demandcheck',
        'demandcheck-clark1': 'index.demandcheck.index.questionnaire',
        'demandcheck-intro': 'index.demandcheck.index.intro',
        'demandcheck-questionnaire': 'index.demandcheck.index.questionnaire',
        home: 'index.manager',
        invitations: 'index.invitations',
        login: 'login',
        'next-mandate-step': 'index.manager',
        'offer-details': 'offers.offer.details',
        questionnaire: 'index.questionnaire',
        'retirement-appointment': 'index.retirement.appointment',
        'sales-appointment': 'sales.appointment',
        upgrade: 'customer.upgrade',
        voucher: 'customer.voucher',
      },
      services: [
        {
          'external-router': 'router',
        },
        'api',
        'auth-redirect',
        'clark-version',
        'config',
        'customer',
        'experiments',
        'features',
        'intl',
        'local-storage',
        'mandate-state',
        'media',
        'messenger',
        'messenger/window',
        'nav-bar',
        'query',
        'retirement-eligibility',
        'select-category',
        'session',
        'settings',
        'shell/platform',
        'title-bar',
        'tracking',
        'user',
      ],
    },
  },

  '@clark-retirement/main': {
    dependencies: {
      externalRoutes: {
        'add-contract': 'index.retirement.wizards',
        demandcheck: 'index.demandcheck.index.questionnaire',
        home: 'index.manager',
        login: 'login',
        'retirement-cockpit': 'index.retirement',
        'upgrade-journey': 'customer.upgrade.index',
        voucher: 'customer.voucher',
      },
      services: [
        {
          'external-router': 'router',
        },
        'api',
        'config',
        'customer',
        'experiments',
        'features',
        'intl',
        'nav-bar',
        'retirement-eligibility',
        'session',
        'tracking',
        'user',
      ],
    },
  },

  '@clark-sales/main': {
    dependencies: {
      externalRoutes: {
        contracts: 'contracts',
        home: 'index.manager',
        login: 'login',
        'offer-checkout': 'offers.offer.checkout',
        questionnaire: 'index.questionnaire',
        recommendations: 'recommendations',
        upgrade: 'customer.upgrade',
      },
      services: [
        {
          'external-router': 'router',
        },
        'api',
        'config',
        'customer',
        'experiments',
        'features',
        'intl',
        'media',
        'session',
        'shell/document-viewer',
        'shell/platform',
        'tracking',
      ],
    },
  },

  '@clarksource/engine-cockpit-retirement': {
    dependencies: {
      externalRoutes: {
        'app-messenger': 'index.messenger.index',
        'category-detail': 'index.manager.category',
        'contract-overview': 'index.manager',
        'demandcheck-intro': 'index.demandcheck.index.intro',
        'demandcheck-start': 'index.demandcheck.index',
        'mandate-not-signed': 'index.manager.landing',
        'product-detail': 'index.manager.product',
        'retirement-category': 'index.retirement.category',
        'retirement-clark2': 'retirement-temporary',
        'start-questionnaire': 'index.questionnaire.index',
        'targeting-category': 'contracts.create.company',
        'targeting-company': 'index.mandate.index.targeting.company-category',
        'targeting-step': 'index.mandate.index.targeting',
        voucher: 'customer.voucher',
      },
      services: [
        {
          'external-router': 'router',
        },
        'api',
        'clark-user-agent',
        'clark-version',
        'config',
        'customer',
        'demandcheck/user',
        'experiments',
        'features',
        'intl',
        'mandate/payback',
        'media',
        'notification-banners',
        'opportunities',
        'questionnaire',
        'redirect-on-completion',
        'select-category',
        'session',
        'shell/document-viewer',
        'shell/platform',
        'title-bar',
        'tracking',
        'user',
      ],
    },
  },
};
