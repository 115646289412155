define("ember-svg-jar/inlined/delete-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7 3.5h2a1 1 0 00-2 0zm-1 0a2 2 0 114 0h4a.5.5 0 010 1h-.564l-1.205 8.838A2.5 2.5 0 019.754 15.5H6.246a2.5 2.5 0 01-2.477-2.162L2.564 4.5H2a.5.5 0 010-1h4zM7 7a.5.5 0 00-1 0v5a.5.5 0 001 0V7zm2.5-.5a.5.5 0 01.5.5v5a.5.5 0 01-1 0V7a.5.5 0 01.5-.5zm-4.74 6.703A1.5 1.5 0 006.246 14.5h3.508a1.5 1.5 0 001.487-1.297L12.427 4.5H3.573l1.187 8.703z\" fill=\"#414759\"/>",
    "attrs": {
      "width": "16",
      "height": "17",
      "viewBox": "0 0 16 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});