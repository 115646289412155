define("ember-svg-jar/inlined/motorcycle-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18 6a1 1 0 100 2h2.127a1 1 0 01.966.744l.598 2.261c-2.27.078-4.937.98-6.522 1.592-.69.266-1.441.2-2.015-.173l-1.445-.94A3 3 0 0010.074 11H3a1 1 0 00-.874 1.486l1.238 2.228A2.5 2.5 0 005.549 16h1.583c.555 0 1.103.079 1.63.23l-.69 1.052a4.5 4.5 0 101.671 1.098l.83-1.265A5.867 5.867 0 0113 21.868V23a1 1 0 001 1h.346a4.393 4.393 0 004.381-4.072 5.107 5.107 0 013.064-4.314l1.004-.435.617 2.334a4.5 4.5 0 101.934-.51L24.815 15H28a1 1 0 100-2h-3.713l-1.26-4.767A3 3 0 0020.127 6H18zm5.948 13.54l.585 2.216a1 1 0 001.934-.512l-.586-2.215a2.5 2.5 0 11-1.933.511zm-1.67-6.317l-1.283.556a7.107 7.107 0 00-4.262 6.003A2.394 2.394 0 0115 21.91v-.043A7.867 7.867 0 007.132 14H5.55a.5.5 0 01-.437-.257L4.7 13h5.374a1 1 0 01.545.162l1.445.94c1.158.751 2.59.838 3.826.36C17.54 13.824 20.073 13 22 13c.073 0 .144-.008.212-.023l.066.246zM5.663 20.951a1 1 0 001.672 1.098l1.257-1.916a2.5 2.5 0 11-1.672-1.097L5.664 20.95z\"/><path d=\"M25.96 8.717a1 1 0 10-1.92.566l.488 1.653a1 1 0 001.919-.565l-.488-1.654z\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});