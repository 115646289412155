define("ember-svg-jar/inlined/legal-expenses-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.571 8h.891l-1.85 10.018c-.581.103-1.097.623-1.022 1.336C3.812 21.468 5.898 23 8.07 23c2.174 0 4.26-1.532 4.482-3.646.074-.713-.442-1.233-1.023-1.336L9.68 8h3.728a3.51 3.51 0 002.173 1.858c-.006.046-.01.094-.01.142v17h-10a1 1 0 100 2h22.334a1 1 0 000-2H17.57V10c0-.048-.003-.096-.01-.142A3.51 3.51 0 0019.734 8h3.728l-1.85 10.018c-.581.103-1.097.623-1.022 1.336C20.812 21.468 22.898 23 25.07 23c2.174 0 4.26-1.532 4.482-3.646.074-.713-.442-1.233-1.023-1.336L26.68 8h.891a1 1 0 100-2h-7.535a3.5 3.5 0 00-6.93 0H5.572a1 1 0 100 2zm11-3a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm-8.5 5.3L9.493 18H6.65l1.421-7.7zm0 10.7c-.93 0-1.697-.442-2.126-1h4.252c-.429.558-1.196 1-2.126 1zm14.874-1h4.252c-.43.558-1.196 1-2.126 1-.93 0-1.697-.442-2.126-1zm.705-2l1.421-7.7 1.422 7.7H23.65z\"/>",
    "attrs": {
      "width": "33",
      "height": "32",
      "viewBox": "0 0 33 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});